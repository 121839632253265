<template>
    <div>
        <v-card height="" flat width="100%" class="preauth-main-card">
            <v-card-text class="pa-0 preauth-card">
                <v-row no-gutters class="pt-1 mr-7">
                    <v-col cols="2" class="pl-2 pr-2 justify-start">
                        <v-img src="@/assets/preauth-dollar.png" width="45px" height="45px"></v-img>
                    </v-col>
                    <v-col cols="">
                        <v-row no-gutters>
                            <v-col cols="12" class=" text-left pl-4">
                                <span class="notice-text">You may see a temporary hold for parking fee, even with
                                    validation.</span>
                            </v-col>
                            <v-col cols="12" class="text-left">
                                <v-btn text x-small class="pa-0  text-left mb-1 pl-4" style="text-transform: none;"
                                    @click="preAuthSheet = true"><span class="learn-more-text">Learn
                                        More</span> <v-icon color="#F2555C" class="material-icons-outlined"
                                        small>chevron_right</v-icon></v-btn>
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-bottom-sheet width="100%" height="30px" persistent v-model="preAuthSheet" style="
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
      " scrollable>
            <v-card width="100%" class="pa-0" style="
          border-top-left-radius: 25px !important;
          border-top-right-radius: 25px !important;
        ">
                <v-container fluid pa-0 class="preauth-sheet-card" style="background-color: #FFEBEB;">
                    <v-row no-gutters class="pl-1 pt-3 pr-1">
                        <v-col cols="12" class="pl-3" style="padding: 16px;">
                            <v-img src="@/assets/preauth-dollar.png" width="65px" height="65px"></v-img>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class=" pl-3 pr-4 pb-3">
                        <v-col cols="12" class="pl-3 ">
                            <h6 class="sheet-heads-up">Heads Up About Your Parking Payment</h6>
                        </v-col>
                        <v-col class="pl-3 pt-3 location-text">
                            <span>This location uses pre-authorization for payment.
                                This means a temporary hold on your bank statement for an amount that's more than the
                                parking fee, even with validation.
                            </span>
                        </v-col>
                    </v-row>
                </v-container>
                <hr style="border: 1px solid #ffeeee !important;" />
                <v-card-text>
                    <v-row no-gutters class="pt-5">
                        <v-col>
                            <h6 class="timeline-header">Here’s what you need to know:</h6>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-6 text-left">
                        <v-col>
                            <v-timeline dense>
                                <v-timeline-item small color="transparent" elevation="0" fill-dot>
                                    <template v-slot:icon class="timeline-item">
                                        <v-img src="@/assets/preauth-dot.png"></v-img>
                                    </template>
                                    <v-row no-gutters>
                                        <v-col cols="12" class="timeline-title">
                                            <span>Initial Charge</span>
                                        </v-col>
                                        <v-col cols="12" class="timeline-sub">
                                            When you start your parking session, the amount charged may be higher than
                                            your actual parking fee. Don’t worry,
                                            this is just to ensure enough funds are available.
                                        </v-col>
                                    </v-row>
                                </v-timeline-item>
                                <v-timeline-item small fill-dot color="transparent" style="margin-top: 10px;">
                                    <template v-slot:icon class="timeline-item">
                                        <v-img src="@/assets/preauth-dot.png"></v-img>
                                    </template>
                                    <v-row no-gutters>
                                        <v-col cols="12" class="timeline-title">
                                            <span>Bank Statement</span>
                                        </v-col>
                                        <v-col cols="12" class="timeline-sub">
                                            You’ll see this initial charge reflected on your bank statement. It’s
                                            essential to understand that this
                                            amount is temporary and not your final parking fee.
                                        </v-col>
                                    </v-row>
                                </v-timeline-item>
                                <v-timeline-item small color="transparent" fill-dot>
                                    <template v-slot:icon class="timeline-item">
                                        <v-img src="@/assets/preauth-dot.png"></v-img>
                                    </template>
                                    <v-row no-gutters>
                                        <v-col cols="12" class="timeline-title">
                                            <span>Final Charge</span>
                                        </v-col>
                                        <v-col cols="12" class="timeline-sub">
                                            The actual parking amount will be adjusted and accurately reflected on your
                                            bank statement within 1 to 10
                                            business days, depending on your bank’s processing time. </v-col>
                                    </v-row></v-timeline-item>
                            </v-timeline>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pt-4">
                    <v-btn @click="preAuthSheet = false" rounded large class=" white--text " color="#f2555c"
                        width="100%" style="text-transform:none;border-radius: 60px !;font-weight: 600;">I
                        Understand</v-btn>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        preAuthSheet: false,
    })
}
</script>
<style>
.dollar-sign {
    font-size: 18px !important;
    color: #F2555C !important;
}

.learn-more-text {
    text-decoration: underline;
    color: #F2555C !important;


}

.sheet-headsup {
    color: #121212;
    font-size: 15px;
    font-style: normal;
    font-weight: bolder;
    line-height: 118%;
    /* 14.16px */
}

.notice-text {
    display: block;
    line-height: 1.5;
    font-size: 11px;
    font-weight: 700px;
    color: #222222;
}

.preauth-card {
    position: relative;
    /* Required for positioning the pseudo-element */
    border-radius: 8px;
    border: 1px solid #FFF;
    background: linear-gradient(146deg, rgba(255, 194, 197, 1) 5.32%, rgba(255, 245, 245, 0.48) 46.29%, #FFFFFF 76.29%);
    padding-left: 80px;
    /* Creates space for the image */
}

.preauth-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    /* Width of the image */
    height: 48px;
    /* Height of the image */
    background-image: url('../assets/preauthcard.svg');
    background-size: cover;
    /* Ensures the image covers the specified area */
    background-repeat: no-repeat;
    opacity: 0.2;
    filter: invert(35%) sepia(93%) saturate(7473%) hue-rotate(359deg) brightness(103%) contrast(101%);
    /* Example filter to change color */

}

.preauth-sheet-card {
    position: relative;
    /* Required for positioning the pseudo-element */
    border: 1px solid #FFF;
    background: #fee2e4;
    padding-left: 80px;
    /* Creates space for the image */
}

.preauth-sheet-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    /* Width of the image */
    height: 150px;
    /* Height of the image */
    background-image: url('../assets/preauth-bottomsheet-pattern.svg');
    background-size: 400px;
    /* Ensures the image covers the specified area */
    background-repeat: repeat;
    opacity: 0.2;
    filter: invert(35%) sepia(93%) saturate(7473%) hue-rotate(359deg) brightness(103%) opacity(1) contrast(101%);
    /* Example filter to change color */

}

.timeline-header {
    color: #121212;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 116.667% */
}

.sheet-heads-up {
    font-size: 14px;
    font-weight: 600 !important;
}

.location-text {
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
}

.dollar-btn {
    color: white !important;
    /* Ensures the button text color is white */
    background-color: white !important;
    /* Ensures the button background color is white */
}

.timeline-title {
    color: #121212;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 10px;
    /* 125% */
}

.timeline-item {
    height: 10px !important;
    width: 10px !important;
    padding-top: 3px !important;

}

/* 
For the divider time line */
.theme--light.v-timeline:before {
    background: rgba(0, 136, 212, 0.5);
    width: 0.7pt;
    height: 67% !important;
}

.v-timeline--dense .v-timeline-item__body {
    max-width: fit-content !important;
}

.v-timeline-item__divider {
    justify-content: left;
    align-items: start !important;
    min-width: 25px !important;
}

.theme--light.v-timeline .v-timeline-item__dot {
    color: transparent;
    height: 12px !important;
    width: 12px !important;
}

.v-timeline-item__dot {
    box-shadow: none;
}

.v-timeline {
    padding-top: 0px;
}

.timeline-sub {
    color: #444;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 5px;
}

.sheet-heads-up {}

.preauth-main-card {
    border-radius: 10px !important;
}
</style>